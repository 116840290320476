.rdp-picker {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  display: flex;
  user-select: none;
  height: 240px;
  background-color: white;
  align-items: center;
}

.rdp-masked-div {
  color: black;
  overflow: hidden;
  height: 155px;
  position: relative;
  mask-image: linear-gradient(
    transparent,
    30%,
    rgba(0, 0, 0, 1),
    60%,
    transparent
  );
  -webkit-mask-image: linear-gradient(
    transparent,
    30%,
    rgba(0, 0, 0, 1),
    60%,
    transparent
  );
}

.rdp-column-container {
  flex-grow: 1;
  display: inline-block;
  touch-action: none;
}

.rdp-column {
  position: absolute;
  pointer-events: none;
  touch-action: none;
  z-index: 0;
  width: 100%;
}

.rdp-reticule {
  border: 0;
  border-top: 2px solid #e9e9e9;
  height: 2px;
  position: absolute;
  width: 80%;
  margin: 0;
  z-index: 100;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.rdp-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 51px;
  font-size: 18px;
}
